<template>
  <div
    class="app-content content"
    :class="[{ 'show-overlay': shallShowOverlay}, contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <div
        class="content-wrapper clearfix"
        :class="containerType"
      >
        <slot name="breadcrumb">
          <app-breadcrumb />
        </slot>
        <div class="content-detached content-right">
          <div class="content-wrapper">
            <div class="content-body">
              <slot />
            </div>
          </div>
        </div>
        <portal-target
          name="content-renderer-sidebar-detached-left"
          slim
        />
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue'
import AppBreadcrumb from '@/vuexy/layouts/components/AppBreadcrumb.vue'
import { userAppConfig } from '@/vuexy/app-config'

export default Vue
  .extend({
    components: {
      AppBreadcrumb,
    },
    setup() {
      const {
        containerType,
        routerTransition,
        contentClass,
        shallShowOverlay,
      } = userAppConfig()

      return {
        containerType,
        routerTransition,
        contentClass,
        shallShowOverlay,
      }
    },
  })
</script>
