import Vue, { CreateElement } from 'vue'
import { useUtils as useI18nUtils } from '@/vuexy/libs/i18n'
import { useUtils as useAclUtils } from '@/vuexy/libs/acl'

const { t } = useI18nUtils()
const { canViewVerticalNavMenuHeader } = useAclUtils()

export default Vue.extend({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  render(h: CreateElement) {
    const span = h('span', {}, [t(this.item.header)])
    const icon = h('feather-icon', { props: { icon: 'MoreHorizontalIcon', size: '18' } })

    if (canViewVerticalNavMenuHeader(this.item)) {
      return h('li', { class: 'navigation-header text-truncate' }, [span, icon])
    }

    return h('li')
  },
})
