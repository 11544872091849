









import Vue from 'vue'
import { computed } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'
import { userAppConfig } from '@/vuexy/app-config'

export default Vue.extend({
  components: {
    BNavItem,
  },
  setup() {
    const { skin } = userAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
})
