import { ref, computed } from '@vue/composition-api'
import { getVerticalMenuStore } from '@/store'

interface VerticalMenuConfig {
  isVerticalMenuActive: boolean;
}

export default function useVerticalNavMenu({ isVerticalMenuActive }: VerticalMenuConfig) {
  const verticalMenuStore = getVerticalMenuStore()

  // ------------------------------------------------
  // isVerticalMenuCollapsed
  // ------------------------------------------------
  const isVerticalMenuCollapsed = computed({
    get: () => verticalMenuStore.isVerticalMenuCollapsed,
    set: val => {
      verticalMenuStore.updateVerticalMenuCollapsed(val)
    },
  })

  // ------------------------------------------------
  // collapseTogglerIcon
  // ------------------------------------------------
  const collapseTogglerIcon = computed(() => {
    if (isVerticalMenuActive) {
      return isVerticalMenuCollapsed.value ? 'unpinned' : 'pinned'
    }

    return 'close'
  })

  const isMouseHovered = ref(false)

  const updateMouseHovered = (val: boolean) => {
    isMouseHovered.value = val
  }

  const toggleCollapsed = () => {
    isVerticalMenuCollapsed.value = !isVerticalMenuCollapsed.value
  }

  return {
    isMouseHovered,
    isVerticalMenuCollapsed,
    collapseTogglerIcon,

    toggleCollapsed,
    updateMouseHovered,
  }
}
