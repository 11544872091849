import {
  ref, computed, watch, WritableComputedRef,
} from '@vue/composition-api'
import { getAppStore, getVerticalMenuStore } from '@/store'

export default function useVerticalLayout(navbarType: WritableComputedRef<string>, footerType: WritableComputedRef<string>) {
  const appStore = getAppStore()
  const verticalMenuStore = getVerticalMenuStore()
  const isVerticalMenuActive = ref(true)

  const toggleVerticalMenuActive = () => {
    isVerticalMenuActive.value = !isVerticalMenuActive.value
  }

  const currentBreakpoint = computed(() => appStore.currentBreakPoint)

  const isVerticalMenuCollapsed = computed(() => verticalMenuStore.isVerticalMenuCollapsed)

  const layoutClasses = computed(() => {
    const classes: string[] = []

    if (currentBreakpoint.value === 'xl') {
      classes.push('vertical-menu-modern')
      classes.push(isVerticalMenuCollapsed.value ? 'menu-collapsed' : 'menu-expanded')
    } else {
      classes.push('vertical-overlay-menu')
      classes.push(isVerticalMenuActive.value ? 'menu-open' : 'menu-hide')
    }

    // Navbar
    classes.push(`navbar-${navbarType.value}`)

    // Footer
    if (footerType.value === 'sticky') {
      classes.push('footer-fixed')
    }

    if (footerType.value === 'static') {
      classes.push('footer-static')
    }

    if (footerType.value === 'hidden') {
      classes.push('footer-hidden')
    }

    return classes
  })

  // ------------------------------------------------
  // Resize handler for Breakpoint
  // ------------------------------------------------
  watch(currentBreakpoint, val => {
    isVerticalMenuActive.value = val === 'xl'
  })

  const overlayClasses = computed(() => {
    if (currentBreakpoint.value !== 'xl' && isVerticalMenuActive.value) {
      return 'show'
    }

    return null
  })

  const navbarTypeClass = computed(() => {
    if (navbarType.value === 'sticky') {
      return 'fixed-top'
    }

    if (navbarType.value === 'static') {
      return 'navbar-static-top'
    }

    if (navbarType.value === 'hidden') {
      return 'd-none'
    }

    return 'floating-nav'
  })

  const footerTypeClass = computed(() => {
    if (footerType.value === 'static') {
      return 'footer-static'
    }

    if (footerType.value === 'hidden') {
      return 'd-none'
    }

    return ''
  })

  return {
    isVerticalMenuActive,
    toggleVerticalMenuActive,
    isVerticalMenuCollapsed,
    layoutClasses,
    overlayClasses,
    navbarTypeClass,
    footerTypeClass,
  }
}
