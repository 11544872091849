


























import Vue from 'vue'
import AppBreadcrumb from '@/vuexy/layouts/components/AppBreadcrumb.vue'
import { userAppConfig } from '@/vuexy/app-config'

export default Vue
  .extend({
    components: {
      AppBreadcrumb,
    },
    setup() {
      const {
        containerType,
        routerTransition,
        contentClass,
        shallShowOverlay,
      } = userAppConfig()

      return {
        containerType,
        routerTransition,

        contentClass,
        shallShowOverlay,
      }
    },
  })
